<template>
    <b-card title="Edit Compressor Warranty">
        <!-- <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Name" v-model="form.name"></b-form-input>
        </b-col> -->

        <b-col cols="12">
            <label>
                Category (Product hierarchy level 1)
            </label>

            <b-form-input
                v-model="form.category">
                
            </b-form-input>
        </b-col>

        <!-- <b-col cols="12">
            <label>
                type
            </label>

            <b-form-select
                v-model="form.type"
                :options="categories">
            </b-form-select>
        </b-col> -->

        <b-col cols="12">
            <label>Type</label>
            <b-form-input placeholder="Type" v-model="form.type"></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Month</label>
            <b-form-input placeholder="Extend Month" v-model="form.month"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="doUpdateCompressorWarranty">Update</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            form: {

            },
        }
    },
    mounted() {
        // this.getCategories();

        this.getCompressorWarranty(this.$route.params.id).then((data) => {
            this.form = data;
        })
    },
    methods: {
        ...mapActions({
            getCompressorWarranty: 'warranty/getCompressorWarranty',
            updateCompressorWarranty: 'warranty/updateCompressorWarranty',
            getCategories: 'category/getCategories',
        }),
        doUpdateCompressorWarranty() {
            this.updateCompressorWarranty(this.form).then(async() => {
                this.$bvToast.toast("Successfully Updated Compressor Warranty", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1000);
                this.$router.go(-1);
            }).catch((e) => {
                this.$bvToast.toast(`Update Compressor Warranty Failed`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
        }
    },
    computed: {
        ...mapState({
            'categories': (state) => {
                return state.category.categories.map((c) => ({ value: c.id, text: c.name }))
            }
        })
    }
}
</script>